import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareSolutions, {
  EasySoftwareSolutionsType,
} from '../EasySoftwareSolutions/EasySoftwareSolutions'

export interface WidgetEasySoftwareSolutionsType
  extends WidgetBase,
  EasySoftwareSolutionsType {
  contentType: 'EasySoftwareSolutions'
}

const WidgetEasySoftwareSolutions: FunctionComponent<WidgetEasySoftwareSolutionsType> = (
  props
) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
    <EasySoftwareSolutions
      displayText={props.displayText}
      solutionsList={props.solutionsList}
    />
  </WidgetContainer>
)
export default WidgetEasySoftwareSolutions
